<template>
  <div style="padding:15px;margin:15px;background:#fff;line-height:1.6;">
    <p>
      <small>
        当前店铺：{{ store.name }}
      </small>
    </p>
    <p>
      <small>
        客户经理：{{ staff.name }}
      </small>
    </p>
  </div>
  <div style="margin:15px;">
    <van-image width="100%" :src="qr" />
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import QRCode from 'qrcode'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      userId: Cookies.get('userId'),
      storeId: Cookies.get('storeId'),
      store: {},
      qr: '',
      staff: {}
    })
    const generateQRcode = () => {
      post('/util.getAllUrls', {
      }).then(res => {
        const url = res.data.shop + 'freePay?storeId=' + state.storeId + '&salespersonId=' + state.staff.id
        console.log(url)
        QRCode.toDataURL(url,
          {
            width: 500,
            margin: 1,
            color: {
              // dark: '#010599FF',
              // light: '#FFBF60FF'
            }
          }
        ).then(image => {
          state.qr = image
        })
      })
    }
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.store = res.data
      })
      post('/staff.get', {
        userId: state.userId,
        storeId: state.storeId
      }).then(res => {
        if (res.code === 0) {
          state.staff = {
            id: res.data.staffId,
            name: res.data.name,
            avatar: res.data.avatar
          }
          generateQRcode()
        } else {
          Toast(res.msg)
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
